// eslint-disable-next-line no-redeclare,no-unused-vars
function ajaxResponse(param, xhr) {
  if (isString(xhr)) showInfo(xhr, 'error');
  if (xhr.id) param.id = xhr.id;

  // first choose
  if (xhr.pick) {
    buildPromptPopup(
      [{label: xhr.pick.label, field: 'select', values: xhr.pick.values}],
      function (value) {
        param.send.picked = value;
        ajax(param);
      }
    );
    return;
  }

  // confirm popup
  else if (xhr.confirm) {
    var pm = {
      title: 'Achtung',
      content: xhr.confirm.question,
      buttons: {},
      closeLabel: info.title.cancel,
    };
    if (!param.send) param.send = {};

    pm.buttons[xhr.confirm.yes] = function () {
      if (param.form)
        param.form
          .attr('action', function () {
            var url = $(this).attr('action');
            url += str_contains(url, '?') ? '&' : '?' + xhr.confirm.id + '=yes';
            return url;
          })
          .submit();
      else {
        param.send[xhr.confirm.id] = 'yes';
        ajax(param);
      }
      delete pm.afterClose;
      popup.close();
    };

    if (xhr.confirm.no)
      pm.buttons[xhr.confirm.no] = function () {
        if (param.form)
          param.form
            .attr('action', function () {
              return $(this).attr('action') + '&' + xhr.confirm.id + '=no';
            })
            .submit();
        else {
          param.send[xhr.confirm.id] = 'no';
          ajax(param);
        }
        popup.close();
      };

    if (xhr.confirm.cancelNo) pm.afterClose = pm.buttons[xhr.confirm.no];
    var popup = buildPopup(pm);
    return;
  }

  if (xhr.parameter)
    $.each(xhr.parameter, function (object, set) {
      parameter[object] = set;
    });
  if (xhr.fields)
    $.each(xhr.fields, function (object, fie) {
      fields[object] = fie;
    });
  if (xhr.dialogForms) dialogForms = xhr.dialogForms;
  if (xhr.calculated) Object.assign(calculated, xhr.calculated);
  // if (param.callbackPre) param.callbackPre(xhr);
  if (xhr.data && !param.noDataController) dataController(xhr.data, xhr, param);
  if (xhr.openLink) window.open(xhr.openLink);
  if (xhr.url)
    buildFilePopup({
      src: xhr.url,
      buttons: param.buttons,
      close: param.close,
    });
  if (xhr.download) download(xhr.download);
  if (xhr.click) param.click = xhr.click;

  // open detail
  if (xhr.open && !param.noOpen) {
    var pm2 = {};
    pm2.id = xhr.open;
    if (xhr.object) pm2.object = xhr.object;
    else pm2.object = param.object;
    if (xhr.objectSub) pm2.objectSub = xhr.objectSub;
    else if (param.objectSub) pm2.objectSub = param.objectSub;
    if (param.view && !xhr.object) {
      if (param.view.top && xhr.switching) pm2.top = param.view.top;
      else pm2.top = param.view;
    }
    if (param.click) pm2.click = param.click;
    if (!param.id) param.id = xhr.open;
    if (xhr.load) ajax(Object.assign(pm2, {func: 'detail'}));
    else detail(pm2);
  } else if (param.detail && xhr.amount > 0) {
    param.id = xhr.ids[0];
    detail(param);
  } else if (param.func && window[param.func]) window[param.func](param, xhr);
  if (xhr.popup) buildPopup({title: xhr.title, content: xhr.popup});
  // if (xhr.iframe) iframe(xhr.iframe, Object.assign({}, param, xhr));

  // close dtail
  if (xhr.close && param.view) param.view.close();

  // info
  if (xhr.info) {
    if (isArray(xhr.info)) xhr.info = xhr.info.join(', ');
    showInfo(xhr.info);
  }
  if (xhr.error) showInfo(xhr.error, 'error');
  else if (xhr.warning) showInfo(xhr.warning, 'warning');
  else if (xhr.success) showInfo(xhr.success, 'success');
  else if (param.successInfo) showInfo(info.success);
  if (xhr.preview) {
    var previewPopup = buildPopup({
      content: xhr.preview,
      title: info.title.preview,
    });
    previewPopup.find('[file]').on(click, function () {
      buildFilePopup({src: $(this).attr('file')});
    });
  }

  // callback
  if (param.callback) {
    param.callback(xhr);
    delete param.callback;
  }

  // furhter functions
  if (xhr.funcs && param.id)
    $.each(xhr.funcs, function (i, func) {
      ajax({
        object: param.object,
        objectSub: param.objectSub,
        id: param.id,
        send: {func: func},
      });
    });

  if (xhr.update) {
    if (param.post) delete param.post;
    if (param.view && param.view.updateData) param.view.updateData();
    else if (param.view) param.view.update(param);
  }
  if (xhr.reload) reload();
}
